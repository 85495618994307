.button {
  @include button-color-scheme($green);

  border-radius: 5px;
  cursor: pointer;
  font-size: .8125rem;
  padding: 6px 15px;
  text-align: center;
  text-transform: uppercase;
  transition-duration: $transition-duration;

  &:disabled {
    opacity: .75;
  }

  &--outline {
    @include button-color-scheme-outline(darken($grey, 30%));
  }

  &--details {
    @include button-color-scheme-outline(darken($grey, 30%));
  }

  &--big {
    align-items: center;
    border-radius: 10px;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    min-height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    i {
      margin-right: 15px;
    }
  }
}

a.button {
  display: inline-block;

  &:hover,
  &:focus {
    color: $white;
  }
}

.btn-remove {
  color: $text-color;
  font-size: 1rem;
  padding: 2px;
  transition: .25s color;

  &:hover {
    color: $blue;
  }
}

.buttons-set {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;

  .back-link {
    color: $text-color;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .required {
    bottom: 100%;
    left: 0;
    position: absolute;
  }
}

.button-radio-group {
  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:checked + .button-radio {
      &::after {
        transform: scale(1);
      }
    }

    & + .button-radio {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 5px;
      padding: 5px 10px;
      position: relative;
      transition-duration: $transition-duration;

      &:hover {
        background-color: $grey-border;
      }

      &::before,
      &::after {
        color: $black;
      }

      &::before {
        background-color: $white;
        border: 2px solid $grey-border;
        border-radius: 50%;
        content: '\00a0';
        flex-shrink: 0;
        line-height: 20px;
        margin-right: 10px;
        text-align: center;
        width: 20px;
      }

      &::after {
        background-color: $black;
        border-radius: 50%;
        content: '';
        height: 10px;
        left: 17px;
        line-height: initial;
        position: absolute;
        top: calc(50% - 5px);
        transform: scale(0);
        transform-origin: center;
        transition-duration: inherit;
        width: 10px;
      }
    }
  }
}
