.products-grid.owl-carousel {
  position: relative;

  .owl-buttons {
    background-color: $grey;
    border-radius: 35px;
    padding: 4px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -3.25rem;

    .owl-next,
    .owl-prev {
      background-color: $white;
      border-radius: 50%;
      color: darken($grey, 30%);
      display: inline-block;
      font-size: .75rem;
      line-height: 34px;
      transition: color .25s;
      width: 34px;

      &:hover {
        color: darken($grey, 50%);
      }
    }

    .owl-prev {
      margin-right: 2px;
    }

    .owl-next {
      margin-left: 2px;
    }
  }

  .owl-item {
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
