#search_mini_form {
  .input-box .search-autocomplete {
    color: $blue;
  }

  .search-autocomplete {
    ul,
    h4 {
      border-color: $blue;
    }

    li.all-product,
    .list-title {
      background-color: $blue;
    }
  }
}
