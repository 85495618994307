.badge {
  background-color: $grey;
  color: $white;
  font-size: .875rem;
  padding: 5px;
  display: inline-block;

  &--new {
    background-color: $white;
    border: 1px solid $blue;
    color: $blue;
  }

  &--onrequest {
    background-color: #555;
    border: 1px solid #555;
    color: $white;
  }

  &--sales {
    align-items: center;
    background-color: #b13c3c;
    border-radius: 50%;
    display: inline-flex;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    height: 44px;
    justify-content: center;
    line-height: .75rem;
    position: absolute;
    right: 6px;
    top: 8px;
    width: 44px;
    z-index: 9999;
  }
}
