.breadcrumbs {
  font-size: .8125rem;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;

  &,
  strong {
    font-weight: 300;
  }

  &__list,
  &__item {
    display: inline-block;
  }

  &__item {
    &:not(:last-of-type)::after {
      content: '>';
      margin-right: 2px;
    }
  }
}
