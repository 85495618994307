.magestore-bannerslider {
  &.container {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .flexslider {
    border: 0;
    box-shadow: none;
    margin-bottom: 15px;

    ol.flex-control-nav {
      bottom: 15px;
      display: none;

      li a {
        background-color: $white;
        height: 12px;
        width: 12px;

        &.flex-active {
          background-color: $blue;
        }
      }
    }

    ul.flex-direction-nav li a {
      opacity: .8;

      &.flex-prev {
        left: 30px;
      }

      &.flex-next {
        right: 30px;
      }

      @media #{$mobile} {
        &.flex-next {
          right: 15px;
        }

        &.flex-prev {
          left: 15px;
        }
      }

      @media #{$smartphone} {
        background-size: 45px;

        &.flex-prev,
        &.flex-next {
          top: 60%;
        }
      }
    }
  }
}
