// Button color scheme
@mixin button-color-scheme($color) {
  background-color: $color;
  border: solid 1px $color;
  color: $white;

  &:hover {
    background-color: darken($color, 10%);
    border-color: darken($color, 10%);
  }
}

@mixin button-color-scheme-outline($color) {
  background-color: $white;
  border: solid 1px $color;
  color: $color;

  &:hover {
    background-color: $color;
    border-color: $color;
    color: $white;
  }
}
