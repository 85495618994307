* {
  box-sizing: border-box;
}

html {
  background-color: $white;
}

body {
  color: $text-color;
  padding-top: 116px;

  @media #{$tablet} {
    padding-top: 163px;
  }

  @media #{$smartphone} {
    padding-top: 270px;
  }
}

main,
section {
  margin-bottom: 30px;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}

ol,
ul,
p,
dl {
  margin-bottom: 0;
  margin-top: 0;
}

dd {
  margin-left: 0;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
}

b {
  font-weight: 700;
}

fieldset {
  border: none;
  padding: 0;
}

hr {
  border-bottom: thin solid $grey;
  color: transparent;
  width: 100%;
}

.no-display,
.hide {
  display: none;
}

.text-center {
  text-align: center;
}

.clearfix {
  clear: both;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max-width;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
