@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
.loading-container {
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center; }
  .loading-container .loading-container__circle {
    animation: spin 1s infinite linear;
    border: 3px solid transparent;
    border-bottom-color: #1e72c5;
    border-radius: 50%;
    display: inline-block;
    height: 75px;
    margin: 10px;
    transition: color 1s, border-radius 3s;
    width: 75px; }
    .loading-container .loading-container__circle--error {
      animation: none;
      border-bottom-color: #1e72c5;
      border-radius: 0; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.badge {
  background-color: #eee;
  color: #fff;
  font-size: .875rem;
  padding: 5px;
  display: inline-block; }
  .badge--new {
    background-color: #fff;
    border: 1px solid #1e72c5;
    color: #1e72c5; }
  .badge--onrequest {
    background-color: #555;
    border: 1px solid #555;
    color: #fff; }
  .badge--sales {
    align-items: center;
    background-color: #b13c3c;
    border-radius: 50%;
    display: inline-flex;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    height: 44px;
    justify-content: center;
    line-height: .75rem;
    position: absolute;
    right: 6px;
    top: 8px;
    width: 44px;
    z-index: 9999; }

* {
  box-sizing: border-box; }

html {
  background-color: #fff; }

body {
  color: #818285;
  padding-top: 116px; }
  @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
    body {
      padding-top: 163px; } }
  @media (max-width: 767px) {
    body {
      padding-top: 270px; } }

main,
section {
  margin-bottom: 30px; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

img {
  max-width: 100%; }

ol,
ul,
p,
dl {
  margin-bottom: 0;
  margin-top: 0; }

dd {
  margin-left: 0; }

ol,
ul {
  list-style: none;
  padding-left: 0; }

b {
  font-weight: 700; }

fieldset {
  border: none;
  padding: 0; }

hr {
  border-bottom: thin solid #eee;
  color: transparent;
  width: 100%; }

.no-display,
.hide {
  display: none; }

.text-center {
  text-align: center; }

.clearfix {
  clear: both; }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }

.breadcrumbs {
  font-size: .8125rem;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left; }
  .breadcrumbs,
  .breadcrumbs strong {
    font-weight: 300; }
  .breadcrumbs__list, .breadcrumbs__item {
    display: inline-block; }
  .breadcrumbs__item:not(:last-of-type)::after {
    content: '>';
    margin-right: 2px; }

.button {
  background-color: #00c551;
  border: solid 1px #00c551;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: .8125rem;
  padding: 6px 15px;
  text-align: center;
  text-transform: uppercase;
  transition-duration: 300ms; }
  .button:hover {
    background-color: #00923c;
    border-color: #00923c; }
  .button:disabled {
    opacity: .75; }
  .button--outline {
    background-color: #fff;
    border: solid 1px #a2a2a2;
    color: #a2a2a2; }
    .button--outline:hover {
      background-color: #a2a2a2;
      border-color: #a2a2a2;
      color: #fff; }
  .button--details {
    background-color: #fff;
    border: solid 1px #a2a2a2;
    color: #a2a2a2; }
    .button--details:hover {
      background-color: #a2a2a2;
      border-color: #a2a2a2;
      color: #fff; }
  .button--big {
    align-items: center;
    border-radius: 10px;
    display: flex;
    font-size: 1.125rem;
    justify-content: center;
    min-height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
    .button--big i {
      margin-right: 15px; }

a.button {
  display: inline-block; }
  a.button:hover, a.button:focus {
    color: #fff; }

.btn-remove {
  color: #818285;
  font-size: 1rem;
  padding: 2px;
  transition: .25s color; }
  .btn-remove:hover {
    color: #1e72c5; }

.buttons-set {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative; }
  .buttons-set .back-link {
    color: #818285;
    font-size: .75rem;
    font-weight: bold;
    text-transform: uppercase; }
  .buttons-set .required {
    bottom: 100%;
    left: 0;
    position: absolute; }

.button-radio-group input[type='checkbox'],
.button-radio-group input[type='radio'] {
  display: none; }
  .button-radio-group input[type='checkbox']:checked + .button-radio::after,
  .button-radio-group input[type='radio']:checked + .button-radio::after {
    transform: scale(1); }
  .button-radio-group input[type='checkbox'] + .button-radio,
  .button-radio-group input[type='radio'] + .button-radio {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    padding: 5px 10px;
    position: relative;
    transition-duration: 300ms; }
    .button-radio-group input[type='checkbox'] + .button-radio:hover,
    .button-radio-group input[type='radio'] + .button-radio:hover {
      background-color: rgba(129, 130, 133, 0.25); }
    .button-radio-group input[type='checkbox'] + .button-radio::before, .button-radio-group input[type='checkbox'] + .button-radio::after,
    .button-radio-group input[type='radio'] + .button-radio::before,
    .button-radio-group input[type='radio'] + .button-radio::after {
      color: #3a3a3b; }
    .button-radio-group input[type='checkbox'] + .button-radio::before,
    .button-radio-group input[type='radio'] + .button-radio::before {
      background-color: #fff;
      border: 2px solid rgba(129, 130, 133, 0.25);
      border-radius: 50%;
      content: '\00a0';
      flex-shrink: 0;
      line-height: 20px;
      margin-right: 10px;
      text-align: center;
      width: 20px; }
    .button-radio-group input[type='checkbox'] + .button-radio::after,
    .button-radio-group input[type='radio'] + .button-radio::after {
      background-color: #3a3a3b;
      border-radius: 50%;
      content: '';
      height: 10px;
      left: 17px;
      line-height: initial;
      position: absolute;
      top: calc(50% - 5px);
      transform: scale(0);
      transform-origin: center;
      transition-duration: inherit;
      width: 10px; }

.products-grid.owl-carousel {
  position: relative; }
  .products-grid.owl-carousel .owl-buttons {
    background-color: #eee;
    border-radius: 35px;
    padding: 4px;
    position: absolute;
    right: 0;
    text-align: center;
    top: -3.25rem; }
    .products-grid.owl-carousel .owl-buttons .owl-next,
    .products-grid.owl-carousel .owl-buttons .owl-prev {
      background-color: #fff;
      border-radius: 50%;
      color: #a2a2a2;
      display: inline-block;
      font-size: .75rem;
      line-height: 34px;
      transition: color .25s;
      width: 34px; }
      .products-grid.owl-carousel .owl-buttons .owl-next:hover,
      .products-grid.owl-carousel .owl-buttons .owl-prev:hover {
        color: #6f6f6f; }
    .products-grid.owl-carousel .owl-buttons .owl-prev {
      margin-right: 2px; }
    .products-grid.owl-carousel .owl-buttons .owl-next {
      margin-left: 2px; }
  .products-grid.owl-carousel .owl-item {
    margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px; }

.cart-top {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3);
  color: #818285;
  padding: 15px;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: scale(0);
  transform-origin: top right;
  transition-duration: .5s;
  z-index: 10000; }
  @media (max-width: 767px) {
    .cart-top {
      width: 100vw; } }
  .cart-top__item {
    font-size: .75rem;
    margin-bottom: 15px; }
    .cart-top__item .cart-top-item {
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .cart-top__item .cart-top-item__image {
        display: inline-block;
        min-width: 75px; }
      .cart-top__item .cart-top-item__details {
        min-width: 200px;
        padding-left: 15px;
        padding-right: 15px; }
      .cart-top__item .cart-top-item__price {
        white-space: nowrap; }
      .cart-top__item .cart-top-item__remove {
        color: #e70029; }
  .cart-top__total {
    display: flex;
    justify-content: space-between; }
  .cart-top__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px; }
    .cart-top__actions .button {
      width: calc(50% - 5px); }
  .cart-top__message {
    margin-bottom: 15px;
    margin-top: 15px;
    min-width: 250px;
    text-align: center; }

.header__cart {
  position: relative; }
  @media (min-width: 1200px) {
    .header__cart:hover .cart-top {
      right: 0;
      top: 100%;
      transform: none; } }
  @media (min-width: 1200px) and (max-width: 767px) {
    .header__cart:hover .cart-top {
      right: -15px;
      top: calc(100% + 15px); } }
  .header__cart .cart-top.active {
    right: 0;
    top: 100%;
    transform: none; }
    @media (max-width: 767px) {
      .header__cart .cart-top.active {
        right: -15px;
        top: calc(100% + 15px); } }

.columns {
  display: grid;
  grid-gap: 30px; }
  @media (min-width: 1200px) {
    .columns {
      grid-auto-flow: column;
      grid-auto-columns: 1fr; } }
  @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
    .columns {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767px) {
    .columns {
      grid-template-columns: 1fr; } }
  .columns--bordered > div:not(:last-of-type) {
    position: relative; }
    .columns--bordered > div:not(:last-of-type)::after {
      position: absolute;
      content: ''; }
    @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px), (min-width: 1200px) {
      .columns--bordered > div:not(:last-of-type)::after {
        top: 0;
        border-left: solid 1px #eee;
        height: 100%;
        left: calc(100% + 15px); } }
    @media (max-width: 767px) {
      .columns--bordered > div:not(:last-of-type)::after {
        border-top: solid 1px #eee;
        width: 100%;
        left: 0;
        top: calc(100% + 15px); } }
  @media (min-width: 769px) and (max-width: 1199px), (min-width: 1200px) {
    .columns--has-sidebar {
      grid-template-columns: 1fr 3fr; } }
  @media (max-width: 767px), (min-width: 426px) and (max-width: 768px) {
    .columns--has-sidebar {
      grid-template-columns: 1fr; } }

.footer {
  background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 1170px 3px;
  color: #818285;
  padding-top: 1px; }
  @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
    .footer {
      background-size: calc(100% - 30px) 5px;
      top: 192px; } }
  .footer > .container {
    margin-bottom: 50px;
    margin-top: 50px; }
  .footer h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-top: 0; }
  .footer p {
    font-size: .875rem;
    line-height: 1.45rem; }
  .footer a {
    font-size: .875rem;
    line-height: 1.5rem; }
    .footer a:hover {
      text-decoration: underline; }

.copyright {
  border-top: 1px solid rgba(129, 130, 133, 0.25);
  font-size: .625rem;
  padding: 15px 0 0;
  text-align: center; }
  .copyright__logo img {
    height: 50px; }

@media (min-width: 1200px) {
  .copyright__text, .copyright__logo {
    line-height: 50px; }
  .copyright__logo {
    flex: initial; } }

@media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
  footer,
  .copyright {
    text-align: center; }
    footer__logo,
    .copyright__logo {
      margin-top: 20px; } }

form input,
form select,
form textarea,
.onestepcheckout input,
.onestepcheckout select,
.onestepcheckout textarea {
  background-color: #fff;
  border: 1px solid rgba(129, 130, 133, 0.25);
  border-radius: 9px;
  color: #818285;
  font-size: .8125rem;
  line-height: 36px;
  outline: none;
  padding: 0 12px;
  transition: border .5s;
  width: 100%; }
  form input:active, form input:focus,
  form select:active,
  form select:focus,
  form textarea:active,
  form textarea:focus,
  .onestepcheckout input:active,
  .onestepcheckout input:focus,
  .onestepcheckout select:active,
  .onestepcheckout select:focus,
  .onestepcheckout textarea:active,
  .onestepcheckout textarea:focus {
    border-color: rgba(3, 3, 4, 0.25); }
  form input:invalid,
  form select:invalid,
  form textarea:invalid,
  .onestepcheckout input:invalid,
  .onestepcheckout select:invalid,
  .onestepcheckout textarea:invalid {
    border-color: #1e72c5; }
  form input[readonly], form input[disabled],
  form select[readonly],
  form select[disabled],
  form textarea[readonly],
  form textarea[disabled],
  .onestepcheckout input[readonly],
  .onestepcheckout input[disabled],
  .onestepcheckout select[readonly],
  .onestepcheckout select[disabled],
  .onestepcheckout textarea[readonly],
  .onestepcheckout textarea[disabled] {
    background-color: #eee; }
  form input.input--flat,
  form select.input--flat,
  form textarea.input--flat,
  .onestepcheckout input.input--flat,
  .onestepcheckout select.input--flat,
  .onestepcheckout textarea.input--flat {
    border: 1px solid transparent;
    border-radius: 9px;
    min-height: 40px;
    text-align: center; }
    form input.input--flat:focus,
    form select.input--flat:focus,
    form textarea.input--flat:focus,
    .onestepcheckout input.input--flat:focus,
    .onestepcheckout select.input--flat:focus,
    .onestepcheckout textarea.input--flat:focus {
      border-color: #1e72c5; }

form input[type='checkbox'], form input[type='radio'],
.onestepcheckout input[type='checkbox'],
.onestepcheckout input[type='radio'] {
  margin-right: 5px;
  width: auto; }

form label,
.onestepcheckout label {
  display: inline-block;
  font-size: .875rem;
  font-weight: normal; }

form select,
.onestepcheckout select {
  min-height: 36px;
  padding-right: 15px; }
  form select option,
  .onestepcheckout select option {
    min-height: 36px; }

form .required em,
form p.required,
.onestepcheckout .required em,
.onestepcheckout p.required {
  color: #e70029;
  font-size: .75rem; }

form .required em,
.onestepcheckout .required em {
  float: right; }

form .wide,
form .one-field,
.onestepcheckout .wide,
.onestepcheckout .one-field {
  margin: 0 0 15px;
  width: 100%; }

form .fields,
form .customer-name,
.onestepcheckout .fields,
.onestepcheckout .customer-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%; }
  form .fields .field,
  form .customer-name .field,
  .onestepcheckout .fields .field,
  .onestepcheckout .customer-name .field {
    flex-basis: calc(50% - 5px); }

form .fieldset,
.onestepcheckout .fieldset {
  margin-bottom: 30px;
  margin-top: 0 !important; }
  form .fieldset ul,
  .onestepcheckout .fieldset ul {
    margin-bottom: 0; }
  form .fieldset .legend,
  .onestepcheckout .fieldset .legend {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 0 0 15px; }

form .form-list,
.onestepcheckout .form-list {
  display: flex;
  flex-direction: column; }
  form .form-list li,
  form .form-list .v-fix,
  .onestepcheckout .form-list li,
  .onestepcheckout .form-list .v-fix {
    margin: 0 0 15px; }
    form .form-list li label,
    form .form-list .v-fix label,
    .onestepcheckout .form-list li label,
    .onestepcheckout .form-list .v-fix label {
      margin-left: 5px; }
    form .form-list li:last-of-type,
    form .form-list .v-fix:last-of-type,
    .onestepcheckout .form-list li:last-of-type,
    .onestepcheckout .form-list .v-fix:last-of-type {
      margin-bottom: 0; }
  form .form-list li:first-of-type,
  .onestepcheckout .form-list li:first-of-type {
    margin-top: 15px; }

form .customer-dob,
.onestepcheckout .customer-dob {
  display: flex;
  justify-content: space-between; }
  form .customer-dob > div,
  .onestepcheckout .customer-dob > div {
    flex-basis: calc(33% - 5px); }

form .validation-advice,
.onestepcheckout .validation-advice {
  color: #e70029;
  font-size: .75rem; }

form .validation-failed:not(button),
form .validation-passed:not(button),
.onestepcheckout .validation-failed:not(button),
.onestepcheckout .validation-passed:not(button) {
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 15px; }

form .validation-passed:not(button),
.onestepcheckout .validation-passed:not(button) {
  background-image: url("../images/ok.svg");
  border-color: #ffc81f; }

form .validation-failed:not(button),
.onestepcheckout .validation-failed:not(button) {
  background-image: url("../images/cancel.svg");
  border-color: #1e72c5; }

select {
  appearance: none;
  background-color: white;
  background-image: url("../images//select_arrows.svg");
  background-position: calc(100% - 5px);
  background-repeat: no-repeat;
  background-size: 6px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 15px; }

.button-set p,
.buttons-set p {
  font-size: .75rem; }

.header {
  background-color: rgba(255, 255, 255, 0.75);
  left: 0;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 300ms;
  z-index: 9995; }
  .header:hover {
    background-color: #fff; }
  .header > .container {
    display: grid;
    grid-gap: 15px;
    grid-template-areas: 'logo phone info info' 'logo search customer cart'; }
    @media (min-width: 1200px) {
      .header > .container {
        grid-gap: 5px;
        grid-template-columns: 1fr 2fr 2fr auto; } }
    @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .header > .container {
        grid-template-areas: 'logo phone customer customer' 'logo info info info' 'search search search cart';
        padding-left: 2%;
        padding-right: 2%; } }
    @media (max-width: 767px) {
      .header > .container {
        grid-template-areas: 'logo cart' 'phone phone' 'info info' 'customer customer' 'search search';
        padding-left: 5%;
        padding-right: 5%; } }
  .header__cart, .header__customer, .header__info, .header__phone {
    align-items: center;
    text-transform: uppercase; }
  .header__phone {
    align-items: center;
    display: grid;
    font-size: 0.6rem;
    grid-area: phone;
    grid-auto-flow: column;
    grid-gap: 15px;
    justify-content: flex-start; }
    .header__phone i {
      font-size: .875rem;
      margin-right: 5px; }
    @media (min-width: 1200px) {
      .header__phone {
        align-items: flex-end; } }
  .header__info {
    display: flex;
    font-size: .875rem;
    grid-area: info;
    justify-content: center; }
    @media (min-width: 1200px) {
      .header__info {
        justify-content: flex-end; } }
    .header__info > ul {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 30px;
      justify-content: flex-end;
      width: 100%; }
      @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
        .header__info > ul {
          display: flex;
          justify-content: space-around; } }
      @media (max-width: 767px) {
        .header__info > ul {
          display: grid;
          grid-auto-flow: inherit;
          grid-gap: 0;
          grid-row-gap: 10px;
          grid-template-columns: repeat(3, 1fr);
          text-align: center; } }
  .header__logo {
    align-items: center;
    display: flex;
    grid-area: logo; }
    @media (min-width: 1200px) {
      .header__logo {
        align-items: flex-start; } }
    .header__logo img {
      height: 70px;
      transition-duration: 300ms; }
  .header__search {
    align-items: center;
    display: flex;
    grid-area: search; }
    .header__search .input-box {
      position: relative; }
    .header__search .search {
      width: 100%; }
      .header__search .search__form {
        border: 1px solid rgba(129, 130, 133, 0.25);
        border-radius: 4px;
        display: flex; }
        .header__search .search__form .search__input, .header__search .search__form .search__button {
          background: none;
          border: 0;
          line-height: 38px;
          transition-duration: 300ms; }
        .header__search .search__form .search__input::placeholder {
          font-style: italic; }
        .header__search .search__form .search__button {
          color: #00c551;
          cursor: pointer;
          padding-left: 10px;
          padding-right: 10px;
          transition-duration: 300ms; }
      .header__search .search__loader {
        color: #1e72c5;
        right: 40px;
        top: 12px; }
  .header__customer {
    align-items: center;
    display: grid;
    font-size: .75rem;
    grid-area: customer;
    grid-auto-flow: column;
    grid-gap: 50px;
    justify-content: center; }
    @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .header__customer {
        grid-gap: 20px; } }
    .header__customer a {
      display: inline-block; }
      .header__customer a:hover {
        text-decoration: none; }
        .header__customer a:hover span {
          text-decoration: underline; }
      .header__customer a .fa {
        font-size: 1.25em;
        margin-right: 10px; }
  .header__cart {
    display: flex;
    grid-area: cart;
    justify-content: flex-end; }
    .header__cart:hover .cart__icon {
      background-color: #00923c;
      text-decoration: none; }
    .header__cart .cart__icon {
      align-items: center;
      background-color: #00c551;
      border-radius: 50%;
      color: #fff;
      display: flex;
      height: 55px;
      justify-content: center;
      position: relative;
      transition-duration: 300ms;
      width: 55px; }
      .header__cart .cart__icon .fa {
        font-size: 1.5rem; }
      .header__cart .cart__icon .qty {
        background-color: #eee;
        border-radius: 50%;
        color: #00c551;
        font-size: .75rem;
        padding: 1px;
        padding-left: 3px;
        padding-right: 3px;
        position: absolute;
        right: 2px;
        top: 2px; }
    .header__cart .cart__message {
      white-space: nowrap; }
  .header--scrolled {
    box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3); }
    .header--scrolled > .container {
      grid-template-areas: 'logo search customer cart'; }
      @media (max-width: 767px) {
        .header--scrolled > .container {
          grid-template-areas: 'logo cart' 'search search'; }
          .header--scrolled > .container .header__customer {
            display: none; } }
    .header--scrolled .header__logo img {
      height: 40px; }
    .header--scrolled .header__cart .cart__icon {
      height: 40px;
      width: 40px; }
    .header--scrolled .header__info,
    .header--scrolled .header__phone {
      display: none; }

.col2-set {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 30px; }
  @media (max-width: 767px), (min-width: 426px) and (max-width: 768px) {
    .col2-set {
      grid-auto-flow: row; } }

.content p {
  margin-bottom: 15px; }

.maintenance-mode-cover {
  align-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  bottom: 0;
  color: #3a3a3b;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11000; }
  .maintenance-mode-cover__logo, .maintenance-mode-cover__text {
    padding: 15px; }
  .maintenance-mode-cover__logo {
    max-width: 400px; }
  .maintenance-mode-cover__text h1 {
    border-bottom: 1px solid currentColor;
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 0; }
  @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
    .maintenance-mode-cover__text {
      text-align: center; } }

.messages, .global-site-notice {
  box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3);
  font-size: .75rem;
  padding: 15px; }

.messages {
  margin-bottom: 15px;
  margin-top: 15px; }

.global-site-notice {
  background-color: #1e72c5;
  bottom: 0;
  color: #fff;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center; }

.onestepcheckout-success-msg,
.success-msg, .onestepcheckout-warning-msg,
.warn-msg, .error-msg,
.onestepcheckout-error-msg {
  font-size: .875rem;
  padding: 5px 15px;
  position: relative; }
  .onestepcheckout-success-msg::before,
  .success-msg::before, .onestepcheckout-warning-msg::before,
  .warn-msg::before, .error-msg::before,
  .onestepcheckout-error-msg::before {
    left: 0;
    position: absolute; }

.onestepcheckout-success-msg,
.success-msg {
  color: #00c551; }
  .onestepcheckout-success-msg::before,
  .success-msg::before {
    content: '✔'; }

.onestepcheckout-warning-msg,
.warn-msg {
  color: #fe771d; }
  .onestepcheckout-warning-msg::before,
  .warn-msg::before {
    content: '⚠'; }

.error-msg,
.onestepcheckout-error-msg {
  color: #e70029; }
  .error-msg::before,
  .onestepcheckout-error-msg::before {
    content: '✖'; }

.navigation-bar {
  margin-bottom: 2px;
  position: relative; }
  .navigation-bar__mobile-header {
    display: none;
    justify-content: space-between;
    line-height: 40px;
    text-transform: uppercase; }
    @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .navigation-bar__mobile-header {
        display: flex; } }
    .navigation-bar__mobile-header .hamburger-icon {
      height: 40px;
      position: relative;
      width: 20px; }
      .navigation-bar__mobile-header .hamburger-icon span {
        border-bottom: 4px solid #888888;
        display: inline-block;
        height: 0;
        left: 0;
        position: absolute;
        top: 20px;
        transition-duration: .5s;
        width: 20px; }
        .navigation-bar__mobile-header .hamburger-icon span:first-of-type {
          top: 13px;
          transform-origin: top left; }
        .navigation-bar__mobile-header .hamburger-icon span:last-of-type {
          top: 27px;
          transform-origin: bottom left; }
  .navigation-bar #navigation-bar-checkbox:checked ~ .navigation-bar__mobile-header .hamburger-icon span:first-of-type {
    transform: rotate(45deg); }
  .navigation-bar #navigation-bar-checkbox:checked ~ .navigation-bar__mobile-header .hamburger-icon span:nth-of-type(2) {
    opacity: 0; }
  .navigation-bar #navigation-bar-checkbox:checked ~ .navigation-bar__mobile-header .hamburger-icon span:last-of-type {
    top: 27px;
    transform: rotate(-45deg); }
  @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
    .navigation-bar #navigation-bar-checkbox:checked ~ .nav-container #nav {
      display: flex; } }
  .navigation-bar #nav {
    display: table;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
    transition-duration: 300ms;
    width: 100%; }
    @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .navigation-bar #nav {
        display: none;
        flex-direction: column; } }
    .navigation-bar #nav a:hover {
      text-decoration: none; }
    .navigation-bar #nav > li {
      background-position: top;
      background-size: 100% 200%;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      display: table-cell;
      transition-duration: .25s; }
      @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
        .navigation-bar #nav > li {
          background-position: bottom;
          border-right: 1px solid rgba(129, 130, 133, 0.25);
          color: #fff;
          padding: 5px; } }
      .navigation-bar #nav > li:hover {
        background-position: bottom;
        color: #fff; }
      .navigation-bar #nav > li:nth-of-type(4n + 1) {
        background-image: linear-gradient(to bottom, transparent 50%, #1e72c5 50%);
        border-bottom-color: #1e72c5;
        border-top-color: #1e72c5; }
      .navigation-bar #nav > li:nth-of-type(4n + 2) {
        background-image: linear-gradient(to bottom, transparent 50%, #ffc81f 50%);
        border-bottom-color: #ffc81f;
        border-top-color: #ffc81f; }
      .navigation-bar #nav > li:nth-of-type(4n + 3) {
        background-image: linear-gradient(to bottom, transparent 50%, #e70029 50%);
        border-bottom-color: #e70029;
        border-top-color: #e70029; }
      .navigation-bar #nav > li:nth-of-type(4n + 4) {
        background-image: linear-gradient(to bottom, transparent 50%, #00c551 50%);
        border-bottom-color: #00c551;
        border-top-color: #00c551; }
      .navigation-bar #nav > li > a {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 45px;
        padding: 0;
        text-align: center; }
        .navigation-bar #nav > li > a .fa {
          margin-right: 5px;
          font-size: 1.1rem; }
      .navigation-bar #nav > li > ul {
        color: #818285;
        min-width: 100%;
        padding-bottom: 7.5px;
        padding-top: 7.5px;
        transform-origin: top;
        white-space: nowrap; }
        .navigation-bar #nav > li > ul ul {
          left: 100%;
          top: 0; }
        .navigation-bar #nav > li > ul li:hover > a {
          text-decoration: underline; }
        .navigation-bar #nav > li > ul a {
          display: block;
          padding: 7.5px 15px; }
      .navigation-bar #nav > li ul {
        background-color: #fff;
        transition-duration: 300ms;
        z-index: 9996; }
        @media (min-width: 1200px) {
          .navigation-bar #nav > li ul {
            box-shadow: 0 0.0625rem 0.375rem rgba(23, 23, 24, 0.3);
            opacity: 0;
            position: absolute;
            visibility: hidden; } }
    .navigation-bar #nav li {
      position: relative; }
      .navigation-bar #nav li:hover > ul {
        opacity: 1;
        visibility: visible; }

.newsletter > .wrapper {
  background-color: #eee;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px; }
  @media (max-width: 767px) {
    .newsletter > .wrapper {
      flex-direction: column; } }

.newsletter__col-left, .newsletter__col-right {
  line-height: 1.1rem;
  padding-bottom: 5px;
  padding-top: 5px; }

.newsletter__col-left {
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .newsletter__col-left {
      text-align: center; } }
  .newsletter__col-left span {
    font-size: 1.25rem; }

.newsletter__col-right {
  flex-basis: 100%;
  max-width: 535px; }
  .newsletter__col-right form {
    position: relative; }
    .newsletter__col-right form input {
      line-height: 39px;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%; }
      .newsletter__col-right form input::placeholder {
        font-style: italic; }
    .newsletter__col-right form button {
      position: absolute;
      right: 8px;
      top: 7px; }
    .newsletter__col-right form .validation-advice {
      color: #e70029;
      margin-top: 5px;
      position: absolute;
      top: 100%; }

.products-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); }
  .products-grid .item {
    border: 1px solid rgba(129, 130, 133, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    transition: .5s box-shadow; }
    .products-grid .item .wrapper {
      padding: 15px; }
    .products-grid .item__name, .products-grid .item__price, .products-grid .item__installments, .products-grid .item__bank-slip {
      flex: 1; }
    .products-grid .item__badges .badge {
      flex-direction: column;
      line-height: 32px;
      min-width: 46px;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 5px; }
      .products-grid .item__badges .badge--new {
        border-bottom-left-radius: 0;
        border-left: none;
        border-top-left-radius: 0;
        left: 1px; }
      .products-grid .item__badges .badge--sales {
        right: 10px;
        top: 10px; }
    .products-grid .item__image img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 100%; }
    .products-grid .item__name {
      font-size: .875rem;
      font-weight: normal; }
    .products-grid .item__price {
      display: none; }
    .products-grid .item__installments:empty {
      display: none; }
    .products-grid .item__bank-slip {
      padding-bottom: 10px; }
    .products-grid .item__actions {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 10px; }
      .products-grid .item__actions .button {
        display: inline-block;
        line-height: 23px;
        padding: 0;
        width: calc(50% - 5px); }

.table,
.data-table {
  border-collapse: collapse;
  display: table;
  margin-bottom: 15px;
  width: 100%; }
  .table colgroup,
  .data-table colgroup {
    display: none; }
  .table td,
  .table th,
  .data-table td,
  .data-table th {
    border: 1px solid #eee;
    border-collapse: collapse;
    font-weight: inherit;
    padding: 15px;
    text-align: center;
    vertical-align: middle; }
    @media (max-width: 767px) {
      .table td,
      .table th,
      .data-table td,
      .data-table th {
        padding: 5px; } }
  .table thead,
  .data-table thead {
    background-color: #eee; }
  .table tbody,
  .data-table tbody {
    font-size: .875rem; }
    .table tbody td,
    .data-table tbody td {
      font-size: .813rem; }
    .table tbody .qty-actions,
    .data-table tbody .qty-actions {
      display: inline-block; }
      .table tbody .qty-actions .qty,
      .data-table tbody .qty-actions .qty {
        background-color: #eee;
        border: 0;
        border-radius: 0;
        height: 26px;
        margin: 0 5px;
        padding-left: 0;
        padding-right: 0;
        pointer-events: none;
        text-align: center;
        width: 34px; }
      .table tbody .qty-actions__buttons,
      .data-table tbody .qty-actions__buttons {
        align-items: center;
        display: flex;
        justify-content: center; }
        .table tbody .qty-actions__buttons a,
        .data-table tbody .qty-actions__buttons a {
          color: #3a3a3b;
          font-size: 1rem;
          width: 20px; }
          .table tbody .qty-actions__buttons a:hover,
          .data-table tbody .qty-actions__buttons a:hover {
            color: #1e72c5; }
      .table tbody .qty-actions .btn-remove,
      .data-table tbody .qty-actions .btn-remove {
        font-size: .813rem; }
    .table tbody .separator,
    .data-table tbody .separator {
      display: none; }
    .table tbody a:hover,
    .data-table tbody a:hover {
      text-decoration: underline; }
  .table tfoot td:first-of-type,
  .data-table tfoot td:first-of-type {
    text-align: left; }
  .table tfoot td:last-of-type,
  .data-table tfoot td:last-of-type {
    text-align: right; }
  .table .columns,
  .data-table .columns {
    align-items: center;
    margin-left: 0;
    margin-right: 0; }
    @media (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .table .columns,
      .data-table .columns {
        display: table;
        width: 100%; } }
    .table .columns__column,
    .data-table .columns__column {
      padding-left: 0;
      padding-right: 0; }

html {
  font-size: 16px; }
  @media (max-width: 767px) {
    html {
      font-size: 14px; } }

body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: normal; }

.emoji {
  font-family: initial;
  font-style: normal;
  font-weight: normal; }

.link {
  color: #ffc81f; }
  .link:hover {
    text-decoration: underline; }

.page-title {
  background-image: linear-gradient(to right, #1e72c5 61%, #ffc81f 61%, #ffc81f 74%, #e70029 74%, #e70029 87%, #00c551 87%);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  margin-bottom: 15px;
  padding-bottom: 1px; }
  @media (max-width: 767px) {
    .page-title {
      padding-top: 5px; } }
  .page-title h1 {
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .page-title h1 {
        margin-top: 10px; } }
  .page-title.title-buttons {
    display: flow-root; }
    .page-title.title-buttons .button {
      float: right;
      margin-bottom: 15px;
      margin-top: calc(-1.5625rem / 2); }

.error {
  color: #e70029; }

.std p {
  margin-bottom: 10px; }

#search_mini_form .input-box .search-autocomplete {
  color: #1e72c5; }

#search_mini_form .search-autocomplete ul,
#search_mini_form .search-autocomplete h4 {
  border-color: #1e72c5; }

#search_mini_form .search-autocomplete li.all-product,
#search_mini_form .search-autocomplete .list-title {
  background-color: #1e72c5; }

.magestore-bannerslider.container {
  padding-left: 0;
  padding-right: 0;
  width: 100%; }

.magestore-bannerslider .flexslider {
  border: 0;
  box-shadow: none;
  margin-bottom: 15px; }
  .magestore-bannerslider .flexslider ol.flex-control-nav {
    bottom: 15px;
    display: none; }
    .magestore-bannerslider .flexslider ol.flex-control-nav li a {
      background-color: #fff;
      height: 12px;
      width: 12px; }
      .magestore-bannerslider .flexslider ol.flex-control-nav li a.flex-active {
        background-color: #1e72c5; }
  .magestore-bannerslider .flexslider ul.flex-direction-nav li a {
    opacity: .8; }
    .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-prev {
      left: 30px; }
    .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-next {
      right: 30px; }
    @media (max-width: 767px), (min-width: 426px) and (max-width: 768px), (min-width: 769px) and (max-width: 1199px) {
      .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-next {
        right: 15px; }
      .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-prev {
        left: 15px; } }
    @media (max-width: 767px) {
      .magestore-bannerslider .flexslider ul.flex-direction-nav li a {
        background-size: 45px; }
        .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-prev, .magestore-bannerslider .flexslider ul.flex-direction-nav li a.flex-next {
          top: 60%; } }

.blue {
  color: #1e72c5; }
