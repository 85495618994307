@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

html {
  font-size: 16px;

  @media #{$smartphone} {
    font-size: 14px;
  }
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: normal;
}

.emoji {
  font-family: initial;
  font-style: normal;
  font-weight: normal;
}

.link {
  color: $yellow;

  &:hover {
    text-decoration: underline;
  }
}

.page-title {
  background-image: linear-gradient(
  to right,
  $border-blue 61%,
  $border-yellow 61%,
  $border-yellow 74%,
  $border-red 74%,
  $border-red 87%,
  $border-green 87%
  );
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  margin-bottom: 15px;
  padding-bottom: 1px;

  @media #{$smartphone} {
    padding-top: 5px;
  }

  h1 {
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 0;
    text-transform: uppercase;

    @media #{$smartphone} {
      margin-top: 10px;
    }
  }

  &.title-buttons {
    display: flow-root;

    .button {
      float: right;
      margin-bottom: 15px;
      margin-top: calc(-1.5625rem / 2);
    }
  }
}

.error {
  color: $red;
}

.std p {
  margin-bottom: 10px;
}
