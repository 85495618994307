.maintenance-mode-cover {
  align-content: center;
  align-items: center;
  background: fade-out($white, .1);
  bottom: 0;
  color: $black;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  justify-content: center;
  left: 0;
  line-height: 1.5;
  margin-bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11000;

  &__logo,
  &__text {
    padding: 15px;
  }

  &__logo {
    max-width: 400px;
  }

  &__text {
    h1 {
      border-bottom: 1px solid currentColor;
      font-size: 1.5rem;
      font-weight: normal;
      margin-top: 0;
    }

    @media #{$mobile} {
      text-align: center;
    }
  }
}
