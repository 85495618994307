.navigation-bar {
  margin-bottom: 2px;
  position: relative;

  &__mobile-header {
    display: none;
    justify-content: space-between;
    line-height: 40px;
    text-transform: uppercase;

    @media #{$mobile} {
      display: flex;
    }

    .hamburger-icon {
      height: 40px;
      position: relative;
      width: 20px;

      span {
        border-bottom: 4px solid darken($grey, 40%);
        display: inline-block;
        height: 0;
        left: 0;
        position: absolute;
        top: 20px;
        transition-duration: .5s;
        width: 20px;

        &:first-of-type {
          top: 13px;
          transform-origin: top left;
        }

        &:last-of-type {
          top: 27px;
          transform-origin: bottom left;
        }
      }
    }
  }

  #navigation-bar-checkbox:checked {
    ~ .navigation-bar__mobile-header .hamburger-icon {
      span {
        &:first-of-type {
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:last-of-type {
          top: 27px;
          transform: rotate(-45deg);
        }
      }
    }

    @media #{$mobile} {
      ~ .nav-container #nav {
        display: flex;
      }
    }
  }

  #nav {
    display: table;
    font-size: .75rem;
    font-weight: 600;
    text-transform: uppercase;
    transition-duration: $transition-duration;
    width: 100%;

    @media #{$mobile} {
      display: none;
      flex-direction: column;
    }

    a:hover {
      text-decoration: none;
    }

    > li {
      background-position: top;
      background-size: 100% 200%;
      border-bottom: 3px solid transparent;
      border-top: 3px solid transparent;
      display: table-cell;
      transition-duration: .25s;

      @media #{$mobile} {
        background-position: bottom;
        border-right: 1px solid $grey-border;
        color: #fff;
        padding: 5px;
      }

      &:hover {
        background-position: bottom;
        color: #fff;
      }

      &:nth-of-type(4n + 1) {
        background-image: linear-gradient(to bottom, transparent 50%, $blue 50%);
        border-bottom-color: $blue;
        border-top-color: $blue;
      }

      &:nth-of-type(4n + 2) {
        background-image: linear-gradient(to bottom, transparent 50%, $yellow 50%);
        border-bottom-color: $yellow;
        border-top-color: $yellow;
      }

      &:nth-of-type(4n + 3) {
        background-image: linear-gradient(to bottom, transparent 50%, $red 50%);
        border-bottom-color: $red;
        border-top-color: $red;
      }

      &:nth-of-type(4n + 4) {
        background-image: linear-gradient(to bottom, transparent 50%, $green 50%);
        border-bottom-color: $green;
        border-top-color: $green;
      }

      > a {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 45px;
        padding: 0;
        text-align: center;

        .fa {
          margin-right: 5px;
          font-size: 1.1rem;
        }
      }

      > ul {
        color: $text-color;
        min-width: 100%;
        padding-bottom: 7.5px;
        padding-top: 7.5px;
        transform-origin: top;
        white-space: nowrap;

        ul {
          left: 100%;
          top: 0;
        }

        li:hover > a {
          text-decoration: underline;
        }

        a {
          display: block;
          padding: 7.5px 15px;
        }
      }

      ul {
        background-color: $white;
        transition-duration: $transition-duration;
        z-index: 9996;

        @media #{$desktop} {
          box-shadow: $box-shadow-bottom;
          opacity: 0;
          position: absolute;
          visibility: hidden;
        }
      }
    }

    li {
      position: relative;

      &:hover {
        > ul {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
