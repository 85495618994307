%message-container {
  box-shadow: $box-shadow-bottom;
  font-size: .75rem;
  padding: 15px;
}

.messages {
  @extend %message-container;

  margin-bottom: 15px;
  margin-top: 15px;
}

.global-site-notice {
  @extend %message-container;

  background-color: $blue;
  bottom: 0;
  color: $white;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: center;
}

%message-status {
  font-size: .875rem;
  padding: 5px 15px;
  position: relative;

  &::before {
    left: 0;
    position: absolute;
  }
}

.onestepcheckout-success-msg,
.success-msg {
  @extend %message-status;

  color: $green;

  &::before {
    content: '✔';
  }
}

.onestepcheckout-warning-msg,
.warn-msg {
  @extend %message-status;

  color: $orange;

  &::before {
    content: '⚠';
  }
}

.error-msg,
.onestepcheckout-error-msg {
  @extend %message-status;

  color: $red;

  &::before {
    content: '✖';
  }
}
