// General store tables
.table,
.data-table {
  border-collapse: collapse;
  display: table;
  margin-bottom: 15px;
  width: 100%;

  colgroup {
    display: none;
  }

  td,
  th {
    border: 1px solid $grey;
    border-collapse: collapse;
    font-weight: inherit;
    padding: 15px;
    text-align: center;
    vertical-align: middle;

    @media #{$smartphone} {
      padding: 5px;
    }
  }

  thead {
    background-color: $grey;
  }

  tbody {
    font-size: .875rem;

    td {
      font-size: .813rem;
    }

    .qty-actions {
      display: inline-block;

      .qty {
        background-color: $grey;
        border: 0;
        border-radius: 0;
        height: 26px;
        margin: 0 5px;
        padding-left: 0;
        padding-right: 0;
        pointer-events: none;
        text-align: center;
        width: 34px;
      }

      &__buttons {
        align-items: center;
        display: flex;
        justify-content: center;

        a {
          color: $black;
          font-size: 1rem;
          width: 20px;

          &:hover {
            color: $blue;
          }
        }
      }

      .btn-remove {
        font-size: .813rem;
      }
    }

    .separator {
      display: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  tfoot {
    td {
      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        text-align: right;
      }
    }
  }

  .columns {
    align-items: center;
    margin-left: 0;
    margin-right: 0;

    @media #{$tablet} {
      display: table;
      width: 100%;
    }

    &__column {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
