.newsletter {
   > .wrapper {
    background-color: $grey;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 30px 40px;

    @media #{$smartphone} {
      flex-direction: column;
    }
  }

  &__col-left,
  &__col-right {
    line-height: 1.1rem;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  &__col-left {
    text-transform: uppercase;

    @media #{$smartphone} {
      text-align: center;
    }

    span {
      font-size: 1.25rem;
    }
  }

  &__col-right {
    flex-basis: 100%;
    max-width: 535px;

    form {
      position: relative;

      input {
        line-height: 39px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;

        &::placeholder {
          font-style: italic;
        }
      }

      button {
        position: absolute;
        right: 8px;
        top: 7px;
      }

      .validation-advice {
        color: $red;
        margin-top: 5px;
        position: absolute;
        top: 100%;
      }
    }
  }
}
