.col2-set {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 30px;

  @media #{$smartphone}, #{$tablet-portrait} {
    grid-auto-flow: row;
  }
}

.content p {
  margin-bottom: 15px;
}
