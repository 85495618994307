.header {
  background-color: fade-out($white, .25);
  left: 0;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: $transition-duration;
  z-index: 9995;

  &:hover {
    background-color: $white;
  }

   > .container {
    display: grid;
    grid-gap: 15px;
    grid-template-areas: 'logo phone info info' 'logo search customer cart';

    @media #{$desktop} {
      grid-gap: 5px;
      grid-template-columns: 1fr 2fr 2fr auto;
    }

    @media #{$tablet} {
      grid-template-areas: 'logo phone customer customer' 'logo info info info' 'search search search cart';
      padding-left: 2%;
      padding-right: 2%;
    }

    @media #{$smartphone} {
      grid-template-areas: 'logo cart' 'phone phone' 'info info' 'customer customer' 'search search';
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  &__cart,
  &__customer,
  &__info,
  &__phone {
    align-items: center;
    text-transform: uppercase;
  }

  &__phone {
    align-items: center;
    display: grid;
    font-size: 0.6rem;
    grid-area: phone;
    grid-auto-flow: column;
    grid-gap: 15px;
    justify-content: flex-start;

    i {
      font-size: .875rem;
      margin-right: 5px;
    }

    @media #{$desktop} {
      align-items: flex-end;
    }
  }

  &__info {
    display: flex;
    font-size: .875rem;
    grid-area: info;
    justify-content: center;

    @media #{$desktop} {
      justify-content: flex-end;
    }

     > ul {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 30px;
      justify-content: flex-end;
      width: 100%;

      @media #{$tablet} {
        display: flex;
        justify-content: space-around;
      }

      @media #{$smartphone} {
        display: grid;
        grid-auto-flow: inherit;
        grid-gap: 0;
        grid-row-gap: 10px;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
      }
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    grid-area: logo;

    @media #{$desktop} {
      align-items: flex-start;
    }

    img {
      height: 70px;
      transition-duration: $transition-duration;
    }
  }

  &__search {
    align-items: center;
    display: flex;
    grid-area: search;

    .input-box {
      position: relative;
    }

    .search {
      width: 100%;

      &__form {
        $radius: 4px;

        border: 1px solid $grey-border;
        border-radius: 4px;
        display: flex;

        .search {
          &__input,
          &__button {
            background: none;
            border: 0;
            line-height: 38px;
            transition-duration: $transition-duration;
          }

          &__input::placeholder {
            font-style: italic;
          }

          &__button {
            color: $green;
            cursor: pointer;
            padding-left: 10px;
            padding-right: 10px;
            transition-duration: $transition-duration;
          }
        }
      }

      &__loader {
        color: $blue;
        right: 40px;
        top: 12px;
      }
    }
  }

  &__customer {
    align-items: center;
    display: grid;
    font-size: .75rem;
    grid-area: customer;
    grid-auto-flow: column;
    grid-gap: 50px;
    justify-content: center;

    @media #{$tablet} {
      grid-gap: 20px;
    }

    a {
      display: inline-block;

      &:hover {
        text-decoration: none;

        span {
          text-decoration: underline;
        }
      }

      .fa {
        font-size: 1.25em;
        margin-right: 10px;
      }
    }
  }

  &__cart {
    display: flex;
    grid-area: cart;
    justify-content: flex-end;

    &:hover {
      .cart__icon {
        background-color: darken($green, 10%);
        text-decoration: none;
      }
    }

    .cart {
      &__icon {
        align-items: center;
        background-color: $green;
        border-radius: 50%;
        color: $white;
        display: flex;
        height: 55px;
        justify-content: center;
        position: relative;
        transition-duration: $transition-duration;
        width: 55px;

        .fa {
          font-size: 1.5rem;
        }

        .qty {
          background-color: $grey;
          border-radius: 50%;
          color: $green;
          font-size: .75rem;
          padding: 1px;
          padding-left: 3px;
          padding-right: 3px;
          position: absolute;
          right: 2px;
          top: 2px;
        }
      }

      &__message {
        white-space: nowrap;
      }
    }
  }

  &--scrolled {
    box-shadow: $box-shadow-bottom;

     > .container {
      grid-template-areas: 'logo search customer cart';

      @media #{$smartphone} {
        grid-template-areas: 'logo cart' 'search search';

        .header__customer {
          display: none;
        }
      }
    }

    .header__logo img {
      height: 40px;
    }

    .header__cart .cart__icon {
      height: 40px;
      width: 40px;
    }

    .header__info,
    .header__phone {
      display: none;
    }
  }
}
