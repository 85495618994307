.cart-top {
  background-color: $white;
  border-radius: 5px;
  box-shadow: $box-shadow-bottom;
  color: $text-color;
  padding: 15px;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: scale(0);
  transform-origin: top right;
  transition-duration: .5s;
  z-index: 10000;

  @media #{$smartphone} {
    width: 100vw;
  }

  &__item {
    font-size: .75rem;
    margin-bottom: 15px;

    .cart-top-item {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &__image {
        display: inline-block;
        min-width: 75px;
      }

      &__details {
        min-width: 200px;
        padding-left: 15px;
        padding-right: 15px;
      }

      &__price {
        white-space: nowrap;
      }

      &__remove {
        color: $red;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    .button {
      width: calc(50% - 5px);
    }
  }

  &__message {
    margin-bottom: 15px;
    margin-top: 15px;
    min-width: 250px;
    text-align: center;
  }
}

@mixin cart-top--opened {
  right: 0;
  top: 100%;
  transform: none;

  @media #{$smartphone} {
    right: -15px;
    top: calc(100% + 15px);
  }
}

.header__cart {
  position: relative;

  @media #{$desktop} {
    &:hover .cart-top {
      @include cart-top--opened;
    }
  }

  .cart-top.active {
    @include cart-top--opened;
  }
}
