.products-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

  .item {
    border: 1px solid $grey-border;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    transition: .5s box-shadow;

    .wrapper {
      padding: 15px;
    }

    &__name,
    &__price,
    &__installments,
    &__bank-slip {
      flex: 1;
    }

    &__badges .badge {
      $size: 32px;

      flex-direction: column;
      line-height: $size;
      min-width: 46px;
      padding: 0;
      position: absolute;
      text-align: center;
      top: 5px;

      &--new {
        border-bottom-left-radius: 0;
        border-left: none;
        border-top-left-radius: 0;
        left: 1px;
      }

      &--sales {
        right: 10px;
        top: 10px;
      }
    }

    &__image {
      img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
      }
    }

    &__name {
      font-size: .875rem;
      font-weight: normal;
    }

    &__price {
      display: none;
    }

    &__installments {
      &:empty {
        display: none;
      }
    }

    &__bank-slip {
      padding-bottom: 10px;
    }

    &__actions {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 10px;

      .button {
        display: inline-block;
        line-height: 23px;
        padding: 0;
        width: calc(50% - 5px);
      }
    }
  }
}
