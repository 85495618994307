.columns {
  $gap: 30px;

  display: grid;
  grid-gap: $gap;

  @media #{$desktop} {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  @media #{$tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$smartphone} {
    grid-template-columns: 1fr;
  }

  &--bordered {
    > div:not(:last-of-type) {
      position: relative;

      &::after {
        position: absolute;
        content: '';
      }

      @media #{$tablet}, #{$desktop} {
        &::after {
          top: 0;
          border-left: solid 1px $grey;
          height: 100%;
          left: calc(100% + #{$gap/2});
        }
      }

      @media #{$smartphone} {
        &::after {
          border-top: solid 1px $grey;
          width: 100%;
          left: 0;
          top: calc(100% + #{$gap/2});
        }
      }
    }
  }

  &--has-sidebar {
    @media #{$tablet-landscape}, #{$desktop} {
      grid-template-columns: 1fr 3fr;
    }

    @media #{$smartphone}, #{$tablet-portrait} {
      grid-template-columns: 1fr;
    }
  }
}
