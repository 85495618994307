.footer {
  background-image: linear-gradient(
  to right,
  $border-blue 61%,
  $border-yellow 61%,
  $border-yellow 74%,
  $border-red 74%,
  $border-red 87%,
  $border-green 87%
  );
  background-position: top;
  background-repeat: no-repeat;
  background-size: 1170px 3px;
  color: $text-color;
  padding-top: 1px;

  @media #{$mobile} {
    background-size: calc(100% - 30px) 5px;
    top: 192px;
  }

  > .container {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.25rem;
    margin-top: 0;
  }

  p {
    font-size: .875rem;
    line-height: 1.45rem;
  }

  a {
    font-size: .875rem;
    line-height: 1.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.copyright {
  border-top: 1px solid $grey-border;
  font-size: .625rem;
  padding: 15px 0 0;
  text-align: center;

  &__logo {
    img {
      height: 50px;
    }
  }
}

@media #{$desktop} {
  .copyright {
    &__text,
    &__logo {
      line-height: 50px;
    }

    &__logo {
      flex: initial;
    }
  }
}

@media #{$mobile} {
  footer,
  .copyright {
    text-align: center;

    &__logo {
      margin-top: 20px;
    }
  }
}
